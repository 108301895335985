// DEPRECATED:
$font-size: 16px;
$width: $font-size * 35;
$two-column-width: $font-size * (24 * 2 + 1);

// Utilities.

// 1rem in pixels.
$unit-px: 12;

@function rem-to-px($rem) {
  @return $rem / 1rem * $unit-px * 1px;
}

// Typography.

$font: "Lato", sans-serif;

$regular: 400;
$bold: 700;

// Standard typographic scale:
// 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 21, 24, 36, 48, 60, 72

// Scale we're going with: "Major Second"
// https://type-scale.com/?size=16&scale=1.125&text=A%20Visual%20Type%20Scale&font=Lato&fontweight=400&bodyfont=Lato&bodyfontweight=400&lineheight=1.5&backgroundcolor=white&fontcolor=%23333&preview=false

// $text-<name>: <pixel-size>rem / $unit-px;
$text-h1: 28.83rem / $unit-px;
$text-h2: 25.63rem / $unit-px;
$text-h3: 22.78rem / $unit-px;
$text-h4: 20.25rem / $unit-px;
$text-h5: 18rem / $unit-px;
$text-h6: 16rem / $unit-px; // Yes it's the same as text-normal
$text-normal: 16rem / $unit-px;
$text-small: 14.22rem / $unit-px;
$text-smaller: 12.64rem / $unit-px;
$text-smallest: 11.24rem / $unit-px;

// Widths for main content.

$padding-content: 1rem;
$width-column: $text-normal * 24;
$width-column-gutter: 1rem;
// We use `box-sizing: border-box` so width should include padding.
$width-content: ($text-normal * 35)+ (2 * $padding-content);
$width-content-2column: ($width-column * 2) + ($width-column-gutter) +
  (2 * $padding-content);

$width-header-button: 4rem;

$width-tutorial-sidebar: 20rem;

// Breakpoints.

@mixin above-small {
  @media (min-width: rem-to-px($width-content) + 1) {
    @content;
  }
}

@mixin below-small {
  @media (max-width: rem-to-px($width-content)) {
    @content;
  }
}

@mixin above-sidebar-visible {
  @media (min-width: rem-to-px($width-tutorial-sidebar + $width-content) + 1px) {
    @content;
  }
}

@mixin below-sidebar-visible {
  @media (max-width: rem-to-px($width-tutorial-sidebar + $width-content)) {
    @content;
  }
}

// Tutorial header & nav.

$height-header: 3rem;
$opacity-faded: 0.75;
$bg-overlay: rgba(#fff, 0.97);
$width-tutorial-nav: 20rem;

// Z-indices.

$z-fixed-overlay: 1000;

$z-headerTitle: $z-fixed-overlay + 10;

$z-userMenuToggle: $z-headerTitle + 2;
$z-userMenu: $z-headerTitle + 1;

$z-otherTutorialsLink: $z-fixed-overlay + 100;
$z-sidebarToggle: $z-headerTitle + 2;
$z-sidebar: $z-headerTitle + 1;

$z-savedStatus: $z-fixed-overlay + 100;

// Colors.

$text-color: #333;

$grey: #808080;
$grey60: hsl(0deg, 0%, 60%);
$grey70: hsl(0deg, 0%, 70%);
$grey80: hsl(0deg, 0%, 80%);
$grey90: hsl(0deg, 0%, 90%);
$grey95: hsl(0deg, 0%, 95%);

$blue: #2684ff; // Stolen from react-select.
$light-blue: #deebff; // Stolen from react-select.
$border-radius: 4px;
$green: #28a745;
$red: #a72828;
$help-green: #afaf20;
$green-bg: #f3fbea;
$red-bg: #fbeaea;

// Shadows.

$box-shadow-border: 0 0 1px rgba(#000, 0.1);
$box-shadow-light: 0 0 3px rgba(#000, 0.03);
$box-shadow: 0 1px 5px rgba(#000, 0.08);
$box-shadow-hover: 0 4px 11px rgba(#000, 0.1);
$box-shadow-overlay: $box-shadow-hover;
$box-shadow-active: 0 2px 1px rgba(#000, 0.1);

// Transitions

$duration-hover: 75ms;
$duration-active: 150ms;
$easing: ease-in-out;
