.katex {
  // KaTeX seems to render things a little large.
  font-size: 1.1em !important;
}

// Enforce the spacing around display math.
.katex-display {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

svg .katex-display {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
