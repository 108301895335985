html,
body,
#__next {
  height: 100%;
}

#__next {
  display: flex;
  flex-direction: column;
}
