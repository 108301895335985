@import "definitions";

// Enable border-box for everything.

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Typography & Vertical Rhythm.

html {
  // Set up 1rem to be one vertical rhythm unit (12px = .75 * 16px).
  font-size: 75%;
}

body {
  // Set the actual default font size
  font-size: $text-normal;
  font-family: $font;
  font-weight: $regular;
  line-height: 2rem; // 1.5 spacing.

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
}

.prose {
  // hyphens: auto;
  // text-align: justify;

  &.no-margin,
  &.no-margin :first-child {
    margin-top: 0;
  }

  .margin-top + & {
    margin-top: 2rem;
  }

  @at-root {
    & h1,
    h1#{&},
    & h2,
    h2#{&},
    & h3,
    h3#{&},
    & h4,
    h4#{&},
    & h5,
    h5#{&},
    & h6,
    h6#{&} {
      margin: 2rem 0 0 0;
      font-weight: $bold;
    }

    & h1,
    h1#{&} {
      font-size: $text-h1;
      line-height: 3rem;
    }

    & h2,
    h2#{&} {
      font-size: $text-h2;
      line-height: 3rem;
    }

    & h3,
    h3#{&} {
      font-size: $text-h3;
      line-height: 2rem;
    }

    & h4,
    h4#{&} {
      font-size: $text-h4;
      line-height: 2rem;
    }

    & h5,
    h5#{&} {
      font-size: $text-h5;
      line-height: 2rem;
    }

    & h6,
    h6#{&} {
      font-size: $text-h6;
      line-height: 2rem;
    }

    & p,
    p#{&} {
      margin: 1rem 0 0 0;
    }

    & blockquote,
    blockquote#{&} {
      margin: 1rem 0 0 2rem;
      font-style: italic;
    }

    & ul,
    ul#{&},
    & ol,
    ol#{&} {
      margin: 1rem 0 0 2rem;
    }

    & ul,
    ul#{&} {
      list-style-type: disc;

      ul {
        margin-top: 0;
        list-style-type: circle;
      }
    }

    & ol,
    ol#{&} {
      list-style-type: decimal;
    }

    & em,
    em#{&} {
      font-style: italic;
    }

    & strong,
    strong#{&} {
      font-weight: $bold;
    }

    & a,
    a#{&},
    & .link,
    .link#{&} {
      cursor: pointer;
      text-decoration: underline;
      color: $blue;

      transition: color 75ms ease-in-out;

      &:hover {
        color: lighten($blue, 10%);
      }
    }

    & button.link,
    button.link#{&} {
      display: inline;
      margin: 0;
      padding: 0;
      font-size: $text-normal;
      background: none;
      border: none;
    }

    & img,
    img#{&} {
      display: block;
      margin-top: 1rem;
      margin-bottom: 1rem;
      max-width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

i {
  font-style: italic;
}

b {
  font-weight: $bold;
}

// Utilities

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;

  @at-root {
    span#{&} {
      display: block;
    }
  }
}

.margin-top-1 {
  margin-top: 1em;
}

.margin-top {
  margin-top: 2rem;
}

.success {
  background: $green-bg;
  border-radius: $border-radius;
  padding: 1rem;
  box-shadow: $box-shadow-border, $box-shadow-light;
}

.error {
  background: $red-bg;
  border-radius: $border-radius;
  padding: 1rem;
  box-shadow: $box-shadow-border, $box-shadow-light;
}

.flex {
  display: flex;

  > *:not(:first-child) {
    margin-left: 1rem;
  }
}

.text-blue {
  color: $blue;
}

.text-green {
  color: $green;
}

.text-small {
  font-size: $text-small;
}

.text-smaller {
  font-size: $text-smaller;
}

.text-smallest {
  font-size: $text-smallest;

  @at-root {
    p#{&} {
      line-height: 1.1rem;
    }
  }
}

.faded {
  opacity: $opacity-faded;
}

.svg-img {
  margin-top: 1rem;
  display: block;
  max-width: 100%;
  height: auto;
  border: 1px solid $grey80;
  border-radius: $border-radius;

  text {
    font-family: $font;
    fill: $text-color;
  }
}

.img {
  display: block;
  width: 100%;
  height: auto;
}

.table {
  border-collapse: collapse;
  width: 100%;

  .prose + & {
    margin-top: 2rem;
  }

  th,
  td {
    border: 1px solid $grey80;
    padding: 0.5rem;
    vertical-align: middle;
    // word-break: break-all;
  }

  &:not(.text-left):not(.text-right) {
    th,
    td {
      text-align: center;
    }
  }

  &.tight,
  .tight {
    td,
    th {
      padding: 0;
    }
  }

  thead td,
  th {
    font-weight: $bold;
  }

  // This doesn't work with collapsed borders...
  thead tr:first-child,
  tbody:first-child tr:first-child {
    th:first-child,
    td:first-child {
      border-top-left-radius: $border-radius;
    }

    th:last-child,
    td:last-child {
      border-top-right-radius: $border-radius;
    }
  }

  tbody:last-child tr:last-child {
    th:first-child,
    td:first-child {
      border-bottom-left-radius: $border-radius;
    }

    th:last-child,
    td:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
}

.visibly-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.opacity-faded {
  opacity: $opacity-faded;
}
