@import "definitions";

.toggle {
  position: fixed;
  z-index: $z-userMenuToggle;
  top: 0;
  right: 0;

  // Center the SVG
  display: flex;
  justify-content: center;
  align-items: center;

  width: $width-header-button;
  height: $height-header;
  padding: 0.5rem 1rem;

  background: $bg-overlay;

  border-style: solid;
  border-color: $grey80;
  border-left-width: 1px;
  border-bottom-width: 1px;

  transition: opacity $duration-hover $easing, color $duration-hover $easing,
    border-bottom-color $duration-active $easing,
    border-bottom-left-radius $duration-active $easing;

  @include above-sidebar-visible {
    border-bottom-left-radius: $border-radius;
    opacity: $opacity-faded;

    &:hover,
    &.toggled {
      opacity: 1;
    }
  }

  svg {
    display: block;
    width: 1.7rem;
    height: auto;
  }

  &:hover {
    color: $blue;
  }

  &.toggled {
    color: $blue;
    border-bottom-left-radius: 0;
    border-bottom-color: #fff;
  }
}

.popup {
  position: fixed;
  z-index: $z-userMenu;
  right: 0;
  top: calc(#{$height-header} - 1px); // Overlapping border

  width: 22rem;
  max-width: 100vw;
  padding: 2rem;
  background: $bg-overlay;

  border-style: solid;
  border-color: $grey80;
  border-width: 1px 0 1px 1px; // No right border
  box-shadow: $box-shadow-overlay;

  border-bottom-left-radius: $border-radius;
  @include above-sidebar-visible {
    border-top-left-radius: $border-radius;
  }

  transition: opacity $duration-active $easing,
    transform $duration-active $easing;
  will-change: transform, opacity;
  transform-origin: calc(100% + 30px) - 30px;
  transform: translateX(0);

  &:not(.toggled) {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transform: rotateX(-15deg) rotateY(5deg) translateX(0);
  }
}
