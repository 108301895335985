@import "definitions";

// Also see css/footer.scss

.bodyContent {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;

  $opacity: 0.5;
  color: rgba($text-color, $opacity);
  text-align: center;
  font-size: $text-smallest;

  padding-top: 12rem;
  @include above-sidebar-visible {
    padding-bottom: 2rem;
  }
  @include below-sidebar-visible {
    // Need to make room for the "Saved status" indicator
    padding-bottom: 3rem;
  }

  p {
    padding-left: $padding-content;
    padding-right: $padding-content;
  }

  hr {
    border: 0;
    height: 1px;
    background: $grey80;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    opacity: $opacity;
  }

  a {
    text-decoration: underline;

    transition: color $duration-hover $easing;

    &:hover {
      color: rgba($text-color, 0.75);
    }
  }
}
