@import "definitions";

.continue {
  margin-top: 2rem;
  display: flex;
  flex-direction: row-reverse;

  &.done {
    opacity: $opacity-faded;
    transition: opacity $duration-hover $easing;

    .section:hover & {
      opacity: 1;
    }

    &:empty {
      display: none;
    }
  }

  .button {
    margin-left: 1rem;
  }
}

.continueNotAllowedMessage {
  margin-left: auto;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid $grey90;
  max-width: 17rem;
  line-height: 1.5rem; // There are 2 lines; this is fine.
  font-size: $text-small;
  border-radius: $border-radius;
  background: $light-blue;
  opacity: 0.5;
  text-align: center;
  color: #000; // Make the text full black since the opacity is light.
  user-select: none;
}

@keyframes section-animate-in {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
}

.section {
  position: relative;
  margin-top: 6rem;

  &.sectionFirst {
    margin-top: 0;

    + .sectionFirst {
      margin-top: 6rem;
    }
  }

  &.sectionAnimateIn {
    animation: section-animate-in 300ms ease-in-out;
  }

  &.sectionEnumerated {
    .sectionDevNoticeVisible,
    .sectionDevNoticeHidden {
      top: 2.3rem;
    }
  }
}

.sectionDevNoticeVisible,
.sectionDevNoticeHidden {
  position: absolute;
  top: 0.3rem;
  right: calc(100% + 1rem);
}

.sectionDevNoticeVisible {
  color: $green;
}

.sectionDevNoticeHidden {
  color: $red;
}

@mixin box($bg, $labeled: false) {
  border-radius: $border-radius;
  padding: if($labeled, 0.5rem 1rem 1rem, 1rem);
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: $bg;
  box-shadow: $box-shadow-border, $box-shadow-light;

  @if (not $labeled) {
    > :first-child,
    > :global(.prose) > :first-child {
      margin-top: 0;
    }
  }
}

.reminder {
  @include box(hsl(0deg, 0%, 95%), $labeled: true);
  opacity: 0.75;
}

.reminderLabel,
.answerLabel {
  display: block;
  font-size: $text-smallest;
  text-transform: uppercase;
  opacity: 0.75;
  line-height: 1.5rem;
}

.reminderLabel {
  & + * {
    margin-top: 0 !important;

    > :first-child {
      margin-top: 0 !important;
    }

    > :last-child,
    > :last-child > :last-child {
      margin-bottom: 0 !important;
    }
  }
}

.answer {
  text-align: left;

  // Specificity hack is necessary...
  :global(.table) &#{&} {
    margin-top: 0.5rem;
    margin-bottom: 0;
    padding: 0.5rem;

    :global(.prose) {
      blockquote,
      p {
        margin-top: 0rem;
      }
      blockquote {
        margin-left: 1rem;
      }
    }
  }
}

.answer.undetermined {
  @include box($light-blue);
}

.answer.correct {
  @include box($green-bg);
}

.answer.incorrect {
  @include box($red-bg);
}

.help {
  @include box($green-bg);
}

.info {
  @include box($light-blue);
}

.boxAnimateIn {
  animation: section-animate-in 150ms ease-in-out;
}

.enumerated {
  position: relative;

  &::before {
    counter-increment: section;
    content: counter(section, upper-alpha) ".";
    position: absolute;
    top: 0;
    left: -2rem;
    font-weight: bold;
    opacity: $opacity-faded;
  }
}
