@import "definitions";

////////////////////////////////////////////////////////////////////////////////
// Button.
////////////////////////////////////////////////////////////////////////////////

.buttonBase {
  display: inline-block;

  &.block {
    display: block;
    width: 100%;
  }

  border-radius: $border-radius;
  padding: 0.5rem 1.5rem;
  font-size: $text-normal;
  font-family: inherit;
  line-height: inherit;
  background: none;
  color: inherit;
  border-width: 1px;
  border-style: solid;
  transition: border-color $duration-hover $easing,
    background-color $duration-hover $easing, box-shadow $duration-hover $easing;

  &:disabled {
    opacity: 0.6;
  }

  &:not(:disabled) {
    cursor: pointer;
    box-shadow: $box-shadow-border, $box-shadow;

    &:hover {
      box-shadow: $box-shadow-border, $box-shadow-hover;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:active {
    transition-duration: $duration-active;
  }

  :global(.octicon) {
    position: relative;
    top: -0.1rem;
    width: 1em;
    height: auto;
  }

  &.iconFirst :global(.octicon) {
    margin-left: -0.4rem;
    margin-right: 0.4rem;
  }

  &.iconLast :global(.octicon) {
    margin-left: 0.3rem;
    margin-right: -0.3rem;
  }
}

@mixin button($color) {
  background-color: $color;
  border-color: $color;
  color: #fff;

  &:not(:disabled) {
    &:hover {
      background-color: darken($color, 7.5%);
      border-color: darken($color, 10%);
    }

    &:active,
    &:focus {
      box-shadow: $box-shadow-border, 0 0 0 2px rgba(darken($color, 3%), 0.5),
        $box-shadow-active;
    }

    &:active {
      background-color: darken($color, 10%);
    }
  }
}

.primary {
  composes: buttonBase;
  @include button($green);
}

.secondary {
  composes: buttonBase;
  @include button($blue);
}

.tertiary {
  composes: buttonBase;
  @include button($help-green);
}

////////////////////////////////////////////////////////////////////////////////
// Text. TextArea. Decimal. Integer.
////////////////////////////////////////////////////////////////////////////////

@mixin input-core {
  font-size: $text-normal;
  font-family: inherit;
  line-height: inherit;

  &:disabled {
    color: $grey60;
    background-color: $grey95;
  }
}

.textInput,
.textArea,
.numberInput {
  @include input-core;
  display: block;
  border: 1px solid $grey80;
  border-radius: $border-radius;
  padding: 0.5rem 0.75rem;

  transition: border-color $duration-hover $easing,
    box-shadow $duration-hover $easing;

  &::placeholder {
    color: $grey;
  }

  &:disabled {
    border-color: $grey90;
  }

  &:not(:disabled):not(:active):not(:focus):hover {
    border-color: $grey70;
  }

  &:focus {
    border-color: $blue;
    box-shadow: 0 0 0 1px $blue;
    outline: none;
  }
}

.textInput {
  width: 100%;
  text-overflow: ellipsis;

  &.textInputMaxWidth {
    max-width: 8rem;
  }
}

.textArea {
  width: 100%;

  // FireFox made the textarea too tall by default; apparently it tries to leave
  // room for a horizontal scroll bar, which we shouldn't ever have.  This rule
  // targets Firefox only.
  // https://stackoverflow.com/a/22700700
  @-moz-document url-prefix() {
    overflow-x: hidden;
  }
}

.numberInput {
  width: 100%;
  max-width: 8rem;
  text-overflow: ellipsis;
}

////////////////////////////////////////////////////////////////////////////////
// Choice.
////////////////////////////////////////////////////////////////////////////////

.choiceChoice {
  display: flex;
  align-items: center;
  border-color: $grey80;
  border-style: solid;
  border-width: 1px;
  margin-top: -1px;

  transition: border-color $duration-hover $easing;

  &:first-child {
    margin-top: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    .choiceRadioBox {
      border-top-left-radius: $border-radius;
    }
  }

  &:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .choiceRadioBox {
      border-bottom-left-radius: $border-radius;
    }
  }

  &:not(.disabled):hover {
    position: relative;
    z-index: 2;
    border-color: $grey70;

    .choiceRadioBox {
      border-color: $grey70;
      background-color: $light-blue;
    }
  }

  &.selected,
  &.focused {
    position: relative;
    z-index: 2;
    border-color: $grey70;

    .choiceRadioBox {
      background-color: $light-blue;
    }
  }

  &:not(.selected):not(.focused).disabled {
    .choiceRadioBox {
      background-color: $grey95;
      border-color: $grey90;
    }
  }
}

.choiceLabel {
  padding: 0.5rem 0.75rem;
}

.choiceRadioBox {
  padding: 0 1rem;
  transition: border-color $duration-hover $easing,
    background-color $duration-hover $easing;
  border-right: 1px solid $grey80;
  align-self: stretch;
  display: flex;
  align-items: center;
}

.choiceRadio {
  display: block;
}

.choiceOtherLabel {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.choiceOtherLabelText {
  padding: 0.5rem 0.25rem 0.5rem 0.75rem;
}

.choiceOtherInput {
  display: block;
  @include input-core;
  flex-grow: 1;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  border: none;
  border-bottom-right-radius: $border-radius;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $blue;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Toggle.
////////////////////////////////////////////////////////////////////////////////

.toggleChoices {
  &.horizontal {
    display: flex;
  }

  &.vertical {
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
  }

  &.grid {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
  }
}

.toggleChoice {
  display: block;
  border: 1px solid $grey80;
  padding: 0.5rem 0.75rem;

  transition: background-color $duration-hover $easing,
    border-color $duration-hover $easing, box-shadow $duration-hover $easing,
    color $duration-hover $easing;

  text-align: center;

  :global(.table) & {
    flex-grow: 1;
  }

  .toggleChoices.horizontal & {
    margin-left: -1px;

    &:first-child {
      margin-left: 0;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .toggleChoices.vertical & {
    margin-top: -1px;

    &:first-child {
      margin-top: 0;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .toggleChoices.grid & {
    &:nth-child(2n) {
      margin-left: -1px;
    }

    &:not(:first-child):not(:nth-child(2)) {
      margin-top: -1px;
    }

    &:first-child {
      border-top-left-radius: $border-radius;
    }

    &:nth-child(2) {
      border-top-right-radius: $border-radius;
    }

    &:nth-last-child(2) {
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }

  &:not(.disabled):not(.selected):hover,
  &.focused {
    border-color: $grey70;
    background-color: $light-blue;
  }

  &:not(.disabled):active,
  &.selected {
    background-color: $blue;
    color: #fff;
    border-color: $blue;
    position: relative;
    z-index: 2;
  }

  &:not(.selected).disabled {
    color: $grey60;
    background-color: $grey95;
    border-color: $grey90;
  }

  &.selected.focused {
    box-shadow: 0 0 0 2px rgba($blue, 0.5);
  }
}

.toggleRadio {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

////////////////////////////////////////////////////////////////////////////////
// FieldGroup.
////////////////////////////////////////////////////////////////////////////////

.label {
  display: block;
  margin-bottom: 1rem;
}

.labelsLeft {
  display: grid;

  .label {
    margin-bottom: 0;
  }

  @include above-small {
    align-items: start;

    &:not(.suffixed) {
      grid-template-areas: "labels fields";
      grid-template-columns: auto 1fr;
    }

    // HACK: This really belongs in the specific field.
    &.suffixed {
      grid-template-areas: "labels fields" "suffix";
      grid-template-columns: auto auto 1fr;

      > *:nth-child(3n) {
        padding: 0.5rem 0;
        margin-left: -0.5rem;
      }
    }

    row-gap: 1rem;
    column-gap: 1rem;

    .label {
      text-align: right;
      padding: 0.5rem 0;
      grid-column: 1;
    }

    .noLabel {
      grid-column: 2;
    }

    > * {
      margin-top: 0 !important;
    }
  }

  @include below-small {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
}

.labelsRight {
  display: grid;

  .label {
    margin-bottom: 0;
  }

  @include above-small {
    align-items: center;

    grid-template-areas: "fields labels";
    grid-template-columns: 1fr auto;

    row-gap: 1rem;
    column-gap: 1rem;

    // Make the field go before the first label.
    grid-auto-flow: dense;

    .label {
      padding: 0.5rem 0;
      grid-column: 2;
    }

    .noLabel {
      grid-column: 1;
    }
  }

  @include below-small {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
}
